.toggle {
  @include scale-down($ipad-port) {
    cursor: pointer;
    float: right;
    margin-top: 7px;
    text-align: right;

    &::before,
    &::after {
      color: $white;
      font-size: 18px; }

    &::before {
      content: 'MENU'; }

    &::after {
      content: '\f0c9';
      font-family: 'FontAwesome';
      margin-left: 5px; }

    .open & {
      &::before {
        content: 'CLOSE'; }
      &::after {
        content: '\f00d'; } } } }
