.hero {
  color: $white;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  .inner {
    @include background-cover(center);
    align-items: center;
    display: flex;
    height: calc(100vh + 1px);
    justify-content: center;
    min-height: 500px; }

  .copy {
    max-width: $page-width;
    position: relative;
    width: 100%;
    z-index: 2; }

  img {
    @include horizontal-center;
    bottom: 20px;
    max-width: 76px;
    position: absolute;

    @include scale-down($ipad-port) {
      max-width: 40px; } }

  h1 {
    font-size: 35px;
    letter-spacing: 3px;
    line-height: 1.2;

    @include scale-down($ipad-land) {
      font-size: 20px; } }

  p {
    font-size: 15px;
    letter-spacing: 1.5px;
    margin-top: 20px;

    @include scale-down($ipad-port) {
      font-size: 10px;
      letter-spacing: 2px; } } }
