form {
  margin: 0 auto;
  max-width: 500px;

  .fieldset {
    @include clearfix; }

  .half {
    float: left;
    width: 50%;

    &:first-child {
      padding-right: 10px; }

    &:last-child {
      padding-left: 10px; } } }
