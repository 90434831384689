@mixin clearfix {
  &::before,
  &::after {
    clear: both;
    content: "";
    display: table; } }

@mixin wrap {
  @include clearfix;
  max-width: $page-width;
  margin: 0 auto; }

@mixin wrap-wide {
  @include clearfix;
  max-width: $page-width-wide;
  margin: 0 auto; }

@mixin wrap-narrow {
  @include clearfix;
  max-width: $page-width-narrow;
  margin: 0 auto; }

@mixin grid($columns, $gutter, $pos) {
  float: $pos;
  padding-right: $gutter + px;
  width: $columns / 24 * 100%; }

@mixin grid-percent($width, $gutter, $pos) {
  float: $pos;
  padding-right: $gutter + px;
  width: $width / $page-width * 100%; }
