// scss-lint:disable all

// Animation KeyFrames
@mixin anime-keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }

  @-moz-keyframes #{$name} {
    @content; }

  @-ms-keyframes #{$name} {
    @content; }

  @-o-keyframes #{$name} {
    @content; }

  @keyframes #{$name} {
    @content; } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }

  &::-moz-placeholder {
    @content; }

  &:-moz-placeholder {
    @content; }

  &:-ms-input-placeholder {
    @content; } }

@mixin highlight {
   ::-moz-selection {
    @content; }

   ::selection {
    @content; } }
