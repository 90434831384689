header {
  font-family: $font-base;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  transition-duration: $anim-speed;
  width: 100%;
  z-index: 5;

  @include scale-up($ipad-land) {
    padding: 50px 0; }

  @include scale-down($ipad-land) {
    background-color: $overlay-dark;
    padding: 15px 0 10px; }

  @include scale-down($ipad-port) {
    min-height: 71px; }

  &.over {
    @include scale-up($ipad-land) {
      background-color: $overlay-dark;
      padding: 35px 0; } }

  &.open {
    @include scale-down($ipad-port) {
      background-color: $overlay-darker;
      min-height: 100%;

      ul {
        top: 50%;
        transform: translateX(-50%) translateY(-50%); } } }

  ul {
    @include scale-up($ipad-port) {
      align-items: flex-start;
      display: flex;
      justify-content: center; }

    @include scale-down($ipad-port) {
      left: 50%;
      position: fixed;
      top: -50%;
      transform: translateX(-50%);
      transition-duration: $anim-speed;
      width: 100%; } }

  li {
    @include scale-up($ipad-land) {
      &:nth-child(2) {
        margin-right: 150px; }

      &:nth-child(3) {
        margin-left: 150px; } }

    a {
      color: $white;
      display: block;
      letter-spacing: 2px;
      text-transform: uppercase;

      @include scale-up($ipad-port) {
        padding: 0 20px; }

      @include scale-down($ipad-port) {
        font-size: 16px;
        letter-spacing: 3px;
        padding: 5px 0; } } } }
