.about {
  background-color: $white;
  position: relative;

  &::before {
    background-attachment: fixed;
    background-image: url('/images/background.png');
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 0;

    @include scale-down($ipad-port) {
      width: 100%; } }

  .inner {
    border-bottom: 40px solid $white;
    border-top: 40px solid $white;
    position: relative;
    z-index: 1; }

  .wrap {
    padding: 50px 0;

    @include scale-up($ipad-port) {
      display: flex; }

    > div {
      width: 100%; } }

  .image {
    @include background-cover(center);

    @include scale-down($ipad-port) {
      margin: 20px auto 0;
      max-width: 90%;
      padding-bottom: 50%; } } }
