.logo {
  @include scale-up($ipad-land) {
    @include centered;
    max-width: 300px;
    padding: 0 20px; }

  @include scale-down-between($ipad-land, $ipad-port) {
    margin: 0 auto 20px;
    max-width: 250px; }

  @include scale-down($ipad-port) {
    box-sizing: content-box;
    float: left;
    max-width: 150px; } }
