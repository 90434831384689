* {
  box-sizing: border-box; }

body {
  @include clearfix;
  background-attachment: fixed;
  background-color: $black;
  background-image: url('/images/background.png');
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5; }

main {
  font-family: $font-base; }

.wrap {
  @include wrap;
  padding: 0 30px;

  @include scale-down($ipad-port) {
    padding: 0 15px; }

  &.wide {
    @include wrap-wide; }

  &.narrow {
    @include wrap-narrow; } }

.equal-height {
  @include clearfix;
  opacity: 0;
  transition-duration: $anim-speed;

  &.loaded {
    opacity: 1; } }

h1 {
  font-size: 20px;
  font-weight: 400; }
