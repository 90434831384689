a {
  text-decoration: none;
  transition-duration: $anim-speed;

  &:hover {
    opacity: .8; } }

img {
  max-width: 100%; }

p {
  margin-bottom: 20px; }

.button,
button {
  appearance: none;
  background-color: $gold;
  border: 0;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 20px 13px;
  text-transform: uppercase;
  transition-duration: $anim-speed;
  width: 100%;

  &:hover {
    opacity: .7; } }
