.gallery {
  background-attachment: fixed;
  background-color: $white;
  background-image: url('/images/background.png');

  .container {
    @include clearfix;

    a {
      @include background-cover(center);
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
      display: block;
      float: left;
      padding-bottom: 50%;
      width: 50%;

      @include scale-up($ipad-land) {
        padding-bottom: 23%;
        width: 25%;

        &:nth-child(4n) {
          border-right: 0; } }

      @include scale-up-between($ipad-port, $ipad-land) {
        padding-bottom: 30%;
        width: 33.3333%;

        &:nth-child(3n) {
          border-right: 0; } }

      @include scale-down($mobile) {
        padding-bottom: 50%;

        &:nth-child(2n) {
          border-right: 0; } } } } }

.lb-data {
  .lb-caption,
  .lb-number {
    font-family: $font-base; } }
