.card {
  background-color: $white;
  padding: 30px;

  @include scale-up($ipad-port) {
    margin-right: 50px;
    max-width: 500px; }

  @include scale-down($ipad-port) {
    margin: 0 auto;
    max-width: 90%; }

  h3 {
    background-color: $black;
    color: $white;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 3px;
    margin-bottom: 20px;
    padding: 20px 20px 17px;
    text-align: center;
    text-transform: uppercase; } }
