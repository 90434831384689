footer {

  .copy {
    background-color: $black;
    color: $white;
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 1.5;
    padding: 80px 0;
    text-align: center;
    text-transform: uppercase; } }
