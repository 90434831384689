%input {
  border: 0;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 15px 20px;
  width: 100%;

  @include placeholder {
    color: $gray;
    letter-spacing: 2px;
    text-transform: uppercase; } }
