// sass-lint:disable clean-import-paths
// -------------------------------------
//   Settings
// -------------------------------------

@import 'core/settings';

// ----- Helpers ----- //
@import 'core/helpers/prefixes';
@import 'core/helpers/mixins';
@import 'core/helpers/layout';
@import 'core/helpers/typography';
@import 'core/helpers/functions';
@import 'core/reset';

// -------------------------------------
//   Libraries
// -------------------------------------

// Google Font
@import 'https://fonts.googleapis.com/css?family=Montserrat:300,400';

// Font Awesome
@import '../../node_modules/font-awesome/scss/font-awesome';

// Lightbox
@import 'lib/lightbox';

// -------------------------------------
//   Core
// -------------------------------------

// ----- Reset ------ //
@import 'core/reset';

// ----- Extenstion Classes ------ //
@import 'core/extends';

// ----- Global ------ //
@import 'core/global';

// -------------------------------------
//   Atoms
// -------------------------------------
@import 'atoms/elements';
@import 'atoms/inputs';
@import 'atoms/toggle';
@import 'atoms/logo';

// -------------------------------------
//   Molecules
// -------------------------------------
@import 'molecules/card';
@import 'molecules/form';

// -------------------------------------
//   Organisms
// -------------------------------------
@import 'organisms/header';
@import 'organisms/hero';
@import 'organisms/about';
@import 'organisms/gallery';
@import 'organisms/contact';
@import 'organisms/footer';

// -------------------------------------
//   Templates
// -------------------------------------
