// scss-lint:disable ColorVariable
// ----- Handle Media Queries ----- //
@mixin scale-up($viewport-width) {
  @media only screen and (min-width: $viewport-width + 1) {
    @content; } }

@mixin scale-down($viewport-width) {
  @media only screen and (max-width: $viewport-width) {
    @content; } }

@mixin scale-up-between($viewport-width-1, $viewport-width-2) {
  @media screen and (min-width: $viewport-width-1 + 1) and (max-width: $viewport-width-2) {
    @content; } }

@mixin scale-down-between($viewport-width-1, $viewport-width-2) {
  @media screen and (max-width: $viewport-width-1) and (min-width: $viewport-width-2 + 1) {
    @content; } }

@mixin scale-height($viewport-height) {
  @media only screen and (max-height: $viewport-height) {
    @content; } }

@mixin shadow($color) {
  box-shadow: 0 10px 20px -10px $color; }

@mixin video {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;

  iframe {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

@mixin video-square {
  padding-bottom: 75%;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;

  iframe {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); }

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

@mixin overlay($color, $opacity, $z) {
  &::before {
    background-color: rgba($color, $opacity);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z; } }

@mixin background-cover($position) {
  background-position: $position;
  background-repeat: no-repeat;
  background-size: cover; }

@mixin background-image-cover($image, $position) {
  background-image: url($image);
  background-position: $position;
  background-repeat: no-repeat;
  background-size: cover; }

@mixin overlay {
  background-color: $overlay;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }
